import { createSlice } from '@reduxjs/toolkit'

const initQuestion = {
  index: 0,
  quizId: "",
  id: "",
  question: null,
  no: "",
  examPaperName: "",
  isMark: false,
  hasDoubt: false,
  hasAnswer: false,
  showResult: false,
  answerRight: false,
  userAnswer: null,
  isRight: null,
  noteContent: "",
};

const questionListSlice = createSlice({
  name: 'quesionList',
  initialState: [],
  reducers: {
    initQuestionList(state, action) {
      return [];
    },
    setQuestion(state, action) {
      const { order } = action.payload;
      let findQuestion = state.find(p => p.order === order);
      if (!findQuestion)
        return [...state, { ...initQuestion, ...action.payload, hasAnswer: !!action.payload.userAnswer }];
    },
    setDoubt(state, action) {
      const { id, hasDoubt } = action.payload;
      return state.map(question => {
        if (question.id === id) {
          return {
            ...question,
            hasDoubt
          };
        }
        return question;
      });
    },
    setUserRecord(state, action) {
      const { order, userAnswer, isRight } = action.payload;
      return state.map(question => {
        if (question.order === order) {
          return {
            ...question,
            userAnswer,
            hasAnswer: true,
            ...(isRight != null && { isRight })
          };
        }
        return question;
      });
    },
    setShowResult(state, action) {
      const { order } = action.payload;
      return state.map(question => {
        if (question.order === order) {
          return {
            ...question,
            showResult: true
          };
        }
        return question;
      });
    },
    setNote(state, action) {
      const { order, note } = action.payload;
      return state.map(question => {
        if (question.order === order) {
          return {
            ...question,
            noteContent: note
          };
        }
        return question;
      });
    },
    setMarked(state, action) {
      const { order, isMark } = action.payload;
      return state.map(question => {
        if (question.order === order) {
          return {
            ...question,
            isMark
          };
        }
        return question;
      });
    },
  },
});

export const {
  initQuestionList,
  setQuestion,
  setDisplayMode,
  setDoubt,
  setShowResult,
  setUserRecord,
  setNote,
  setMarked
} = questionListSlice.actions;
export default questionListSlice.reducer;