import { combineReducers } from "redux";
import examInfoReducer from "./examInfoSlice";
import questionListReducer from "./questionListSlice";
import speechInfoReducer from "./speechInfo";
import writingInfoReducer from "./writingInfo";



export default combineReducers({
    examInfo: examInfoReducer,
    questionList: questionListReducer,
    speechInfo: speechInfoReducer,
    writingInfo: writingInfoReducer
});