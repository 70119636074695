import React, { useState, useEffect, useContext, createContext, useCallback } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux';
import useExamActivity from "./examActivity";
import { setPermitServerStatus } from "../reducers/examActivity/examInfoSlice";
import { use } from "react";

const MyWebSocketContext = createContext();

export function MyWebSocketProvider({ children }) {
    const myWebSocket = useMyWebSocketProvider();
    return <MyWebSocketContext.Provider value={myWebSocket}>{children}</MyWebSocketContext.Provider>;
}

export const useMyWebSocket = () => {
    return useContext(MyWebSocketContext);
};


const useMyWebSocketProvider = () => {
    const [socketUrl, setSocketUrl] = useState(null);
    const { handePermitResponse } = useExamActivity();
    const userStore = useSelector(state => state.user);

    const socketOptions = {
        share: true,
        shouldReconnect: (closeEvent) => {
            if (socketUrl === null)
                return false;
            return true;
        }
    };
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
        socketUrl,
        socketOptions
    );

    useEffect(() => {

        if (userStore && userStore?.isAuthed)
            return;

        console.log("close socket");
        setSocketUrl(null);

    }, [userStore?.isAuthed])

    useEffect(() => {

        if (readyState > 0 && readyState !== WebSocket.CLOSED)
            return;

        if (!userStore?.userAuthInfo?.webSocketToken)
            setSocketUrl(null);
        else {
            const newSocketUrl = process.env.REACT_APP_TAGEASY_WEBSOCKET_URL + `?token=${userStore.userAuthInfo?.webSocketToken}`;
            if (!getWebSocket() || newSocketUrl !== getWebSocket().url)
                setSocketUrl(newSocketUrl);
        }
    }, [userStore.userAuthInfo?.webSocketToken, readyState]);



    useEffect(() => {

        if (readyState !== WebSocket.OPEN)
            return;

        sendUserEvent("heartbeat", { ts: Date.now() });

        const intervalId = setInterval(() => {
            sendUserEvent("heartbeat", { ts: Date.now() });
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };

    }, [userStore.userAuthInfo?.webSocketToken, readyState]);


    useEffect(() => {
        if (!lastMessage)
            return;
        const messgage = JSON.parse(lastMessage.data);
        console.log("message", messgage);
        switch (messgage?.action) {
            case "permitResponse":
                handePermitResponse(messgage.data);
                break;
            case "permitStatus":
                setPermitServerStatus(messgage.data);
                break;
            default:
                break;
        }

    }, [lastMessage])

    const sendUserEvent = (eventName, payload = {}) => {
        var eventObj = {
            eventName,
            payload
        };
        var message = { action: "sendevent", data: eventObj };
        sendMessage(JSON.stringify(message));
    }

    const sendAction = (actionName, data = {}) => {
        const payload = {
            actionName,
            data
        };
        var message = { action: "sendaction", payload };
        sendMessage(JSON.stringify(message));
    }

    return {
        lastMessage,
        sendUserEvent,
        sendAction
    };
};