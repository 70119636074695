import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversationReportInfo:[],
  conversationInfo:[],
  submitCorrect:false,
  speechSprintAnimtion:false,
  isReceivingResponse:false
};

const speechInfo = createSlice({
  name: 'speechInfo',
  initialState: initialState,
  reducers: {
    initExamInfo(state, action) {
      return { ...initialState };
    },
    setConversationReportInfo(state, action) {
      return { ...state, conversationReportInfo: action.payload };
    },
    setConversationInfo(state, action) {
      return { ...state, conversationInfo: action.payload };
    },
    setSubmitCorrect(state, action) {
      return { ...state, submitCorrect: action.payload };
    },
    setIsReceivingResponse(state, action) {
      return { ...state, isReceivingResponse: action.payload };
    },
    setSpeechSprintAnimtion(state, action){
      state.speechSprintAnimtion = action.payload;
    },
    clearExamInfo() {
      return initialState;
    }
  }
});

export const {
  initExamInfo,
  clearExamInfo,
  setConversationReportInfo,
  setConversationInfo,
  setSubmitCorrect,
  setSpeechSprintAnimtion,
  setIsReceivingResponse
} = speechInfo.actions;

export default speechInfo.reducer;