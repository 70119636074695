import api from "../api"

const applyPlacementExam = async (placementExamId) => {
    const data = {
        placementExamId: placementExamId
    };
    return await api.post("/ExamProcedure/PlacementExam", data);
}

const applyTrainingExam = async (examSubjectId) => {
    const data = {
        examSubjectId: examSubjectId
    };
    return await api.post("/ExamProcedure/TrainingExam", data);
}
const applyKpTrainingExam = async (knowledgePointId) => {
    const data = {
        knowledgePointId: knowledgePointId
    };
    return await api.post("/ExamProcedure/TrainingKpExam", data);
}

const applySprintTrainingExam = async (sprintItemId) => {
    const data = {
        userSprintItemId: sprintItemId
    };
    return await api.post("/ExamProcedure/TrainingSprintExam", data);
}

const applyInitTrainingExam = async (examPackageId) => {
    const data = {
        examPackageId: examPackageId
    };
    return await api.post("/ExamProcedure/TrainingInitExam", data);
}


const getExamInfo = async (recordId) => {
    return await api.get(`/ExamProcedure/${recordId}`);
};

const getExamReport = async (recordId) => {
    return await api.get(`/ExamProcedure/${recordId}/Report`);
};

const getQuestion = async (recordId, questionId, no) => {
    const params = {
        no: no
    };
    return await api.get(`/ExamProcedure/${recordId}/Questions/${questionId}`, { params });
};
const getNextQuestion = async (recordId) => {
    return await api.get(`/ExamProcedure/${recordId}/NextQuestion`);
}

const answer = async (recordId, questionId, no, userAnswer, remainingTime) => {
    const data = {
        questionId: questionId,
        no: no,
        userAnswer: userAnswer,
        remainingTime: remainingTime,
    };
    return await api.post(`/ExamProcedure/${recordId}/Answer`, data);
}

const setDoubt = async (recordId, questionId, hasDoubt) => {
    const data = {
        questionId: questionId,
        hasDoubt: hasDoubt
    };
    return await api.post(`/ExamProcedure/${recordId}/Doubt`, data);
}

const finished = async (recordId) => {
    return await api.post(`/ExamProcedure/${recordId}/Finished`);
}


const Trainingfinished = async (recordId) => {

    return await api.post(`/ExamProcedure/${recordId}/Finished`);
}


const getUserAnswers = async (recordId) => {
    return await api.get(`/ExamProcedure/${recordId}/UserAnswers`);
}


const getTrainingInfo = async (recordId, roundNum) => {

    return await api.get(`/ExamProcedure/${recordId}?roundNum=${roundNum}`)
}

//NCU前測實驗專用
const getNcuTestInfo = async () => {
    return await api.get(`/ExamProcedure/NcuTest`)
}
//NCU前測實驗專用

export default {
    applyPlacementExam,
    applyTrainingExam,
    applyKpTrainingExam,
    applyInitTrainingExam,
    applySprintTrainingExam,
    getExamInfo,
    getQuestion,
    getTrainingInfo,
    answer,
    finished,
    Trainingfinished,
    getUserAnswers,
    setDoubt,
    getExamReport,
    getNcuTestInfo,
    getNextQuestion

};