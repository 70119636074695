import React, { useState, useEffect, useContext, createContext, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    useParams, useNavigate
} from "react-router-dom";
import authApi from "../services/general/auth";
import myAccountApi from "../services/general/myAccount";
import { setUserAuth, setUserData, reloadUserAuth, clearUserAuth } from '../reducers/user';
import { Refresh } from "@material-ui/icons";
const AuthContext = createContext();

export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};


function useAuthProvider() {
    const dispatch = useDispatch();
    const userStore = useSelector(state => state.user);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(reloadUserAuth());
    }, []);

    useEffect(() => {
        if (!userStore?.userAuthInfo)
            return;
        let now = Date.now();
        if (new Date(userStore.userAuthInfo.accessTokenExpiredTime) > now) {
            setRefreshTokenTimmer(userStore.userAuthInfo.accessTokenExpiredTime);
            if (!userStore.user) {
                fetchUserInfo();
            }
        }
        else if (new Date(userStore.userAuthInfo.refreshTokenExpiredTime) > now) {
            refreshToken();
        }
        else {
            logout(false);
        }
    }, [userStore?.userAuthInfo]);



    const fetchUserInfo = async () => {
        const userResponse = await myAccountApi.getUserInfo();
        dispatch(setUserData(userResponse.data.data));
    }

    const setRefreshTokenTimmer = (accessTokenExpiredTime) => {
        let now = Date.now();
        let delay = new Date(accessTokenExpiredTime).getTime() - now - 60 * 1000;
        if (delay < 0)
            delay = 0;

        setTimeout(refreshToken, delay);
    }

    const refreshToken = async () => {
        try {
            await authApi.refreshToken(userStore.userAuthInfo.refreshToken)
        } catch {
            logout(false);
        }
    }


    const login = async (enterpriseId, username, password) => {
        await authApi.login(enterpriseId, username, password)
            .then(function (response) {
                let userAuth = response.data.data;
                dispatch(setUserAuth(userAuth));
            });
        return
    };

    const logout = (isOnlyClear) => {
        dispatch(clearUserAuth());
        if (!isOnlyClear)
            navigate("/");
    }


    const confirmPasswordReset = async (username, password, resetToken) => {
        await myAccountApi.resetpassword(username, password, resetToken)
            .then(function (response) {
                let userAuth = response.data.data;
                dispatch(setUserAuth(userAuth));
            });
        return

    };

    const loginByOneTimeToken = async (token) => {
        await authApi.tokenLogin(token)
            .then(function (response) {
                let userAuth = response.data.data;
                dispatch(setUserAuth(userAuth));

                if (userAuth.returnUrl) {
                    navigate(userAuth.returnUrl);
                }
                else {
                    navigate("/");
                }
            });
        return
    };

    const isAuthed = () => {
        return userStore.isAuthed;
    }


    return {
        isAuthed,
        login,
        loginByOneTimeToken,
        logout,
        confirmPasswordReset,
        fetchUserInfo,
    };
}