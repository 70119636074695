import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import examProcedureApi from "../services/general/examProcedure";
import { setPermitResponseCode } from '../reducers/examActivity/examInfoSlice';
import Crunker from 'crunker';
const useExamActivity = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const examInfo = useSelector(state => state.examActivity.examInfo);

    const startPlacementTest = async (placementExamId) => {
        let recordRes = await examProcedureApi.applyPlacementExam(placementExamId)
        const recordId = recordRes.data.data.id
        navigate(`/examactivity/${recordId}`);

    }
    const startTraining = async (examSubjectId) => {
        let recordRes = await examProcedureApi.applyTrainingExam(examSubjectId)
        const recordId = recordRes.data.data.id
        navigate(`/examactivity/${recordId}`);
    }

    const startKpTraining = async (knowledgePointId) => {
        let recordRes = await examProcedureApi.applyKpTrainingExam(knowledgePointId)
        const recordId = recordRes.data.data.id
        navigate(`/examactivity/${recordId}`);
    }

    const startSprintTraining = async (sprintItemId, sprintInfo) => {
        let recordRes = await examProcedureApi.applySprintTrainingExam(sprintItemId)
        const recordId = recordRes.data.data.id
        navigate(`/examactivity/${recordId}`, { state: { sprintItemId: sprintItemId, sprintInfo: sprintInfo } })
    }

    const startInitTraining = async (examPackageId) => {
        let recordRes = await examProcedureApi.applyInitTrainingExam(examPackageId)
        const recordId = recordRes.data.data.id
        navigate(`/examactivity/${recordId}`);
    }

    const concatAudios = async (audioSources) => {
        return new Promise(async (resolve, reject) => {
            try {
                const crunker = new Crunker();
                await crunker.fetchAudio(...audioSources)
                    .then((buffers) => {
                        return crunker.concatAudio(buffers);
                    })
                    .then((merged) => {
                        return crunker.export(merged, 'audio/mp3');
                    })
                    .then((output) => {
                        resolve(output.url);
                    });
            } catch (e) {
                console.error(e);
                reject("");
            }
        });
    }

    const handePermitResponse = (data) => {
        dispatch(setPermitResponseCode(data.responseCode));
    }

    return {
        startPlacementTest,
        startTraining,
        startKpTraining,
        startInitTraining,
        startSprintTraining,
        concatAudios,
        handePermitResponse
    };
};
export default useExamActivity;