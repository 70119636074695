import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  writingSprintAnimtion: false,
  currentUserContent: false,
  currentUserPreContent: false,
  submitWritingCorrect: false,
  submitWritingPreCorrect: false,
  writingReport: null,
  writingPreReport: null,
  isReceivingWritingResponse:false
};

const writingInfo = createSlice({
  name: 'writingInfo',
  initialState: initialState,
  reducers: {
    initExamInfo(state, action) {
      return { ...initialState };
    },
    setWritingSprintAnimtion(state, action) {
      return { ...state, writingSprintAnimtion: action.payload };
    },
    setCurrentUserContent(state, action) {
      return { ...state, currentUserContent: action.payload };
    },
    setCurrentUserPreContent(state, action) {
      return { ...state, currentUserPreContent: action.payload };
    },
    setSubmitWritingCorrect(state, action) {
      return { ...state, submitWritingCorrect: action.payload };
    },
    setSubmitWritingPreCorrect(state, action) {
      return { ...state, submitWritingPreCorrect: action.payload };
    },
    setWritingReport(state, action) {
      return { ...state, writingReport: action.payload };
    },
    setWritingPreReport(state, action) {
      return { ...state, writingPreReport: action.payload };
    },
    setIsReceivingWritingResponse(state, action) {
      return { ...state, isReceivingWritingResponse: action.payload };
    },
    clearExamInfo() {
      return initialState;
    }
  }
});

export const {
  initExamInfo,
  clearExamInfo,
  setWritingSprintAnimtion,
  setCurrentUserContent,
  setCurrentUserPreContent,
  setSubmitWritingCorrect,
  setSubmitWritingPreCorrect,
  setWritingReport,
  setWritingPreReport,
  setIsReceivingWritingResponse
} = writingInfo.actions;

export default writingInfo.reducer;